import React from "react";
import { TourItemsType } from "../../../types/App.types.";
import css from "./Card.module.css";
import { getDateFromTimeStampInDay } from "../../../utils/date.utils";

type CardType = {
  item: TourItemsType;
};
export const Card = ({ item }: CardType) => {
  const { title, type, days, route, link, start, minPrice, maxPrice } = item;

  const priceString = maxPrice
    ? `${minPrice} - ${maxPrice} RUR`
    : `от ${minPrice} RUR`;
  return (
    <a className={css.container} href={link} target="_blank" rel="noreferrer">
      <div>
        <div className={css.heading}>
          <div>
            <h3 className={css.title}>{title}</h3>
            <p>
              <b>Тип тура: </b>
              {type}
            </p>
          </div>

          <button className={css.cta}>Смотреть программу</button>
        </div>

        <p className={css.routeContainer}>
          <b>Маршрут: </b>
          {route.replaceAll("&#34;", '"')}
        </p>
        <p>
          <b>Выезд: </b>
          {start
            ? `${getDateFromTimeStampInDay(
                new Date(start.replace(/\./g, "/")).getTime()
              )} - ${getDateFromTimeStampInDay(
                new Date(
                  new Date(start.replace(/\./g, "/")).getTime() + days * 8.64e7
                ).getTime()
              )}`
            : "любые даты выезда"}
        </p>
        <p>
          <span className={css.price}>{priceString}</span>
        </p>
      </div>
    </a>
  );
};
