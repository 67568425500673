export enum TourItemType {
  Corporate = "Корпоративный",
  School = "Школьный",
  Thematic = "Тематический",
  ForAutogroups = "Для автогрупп",
  Individual = "Инивидуальный",
  NewYear = "Новогодний",
  Sbornyi = "Сборный",
  SbornyiExcursion = "Сборный экскурсионный",
}

export type TourItemsType = {
  title: string;
  start?: string;
  startTime?: string;
  minPrice: number;
  maxPrice?: number;
  days: number;
  type: TourItemType;
  route: string;
  link?: string;
};
