export function today() {
  let d = new Date();
  let currDate = d.getDate();
  let currMonth = d.getMonth() + 1;
  let currYear = d.getFullYear();
  return (
    currYear +
    "-" +
    (currMonth < 10 ? "0" + currMonth : currMonth) +
    "-" +
    (currDate < 10 ? "0" + currDate : currDate)
  );
}

export const getDateFromTimeStampInDay = (timestamp?: number) => {
  if (!timestamp) return "";
  return new Date(timestamp).toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};
