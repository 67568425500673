import React from "react";
import "./App.css";
import { Filters } from "./components/Filters/Filters";
import { Cards } from "./components/Cards/Cards";
import { FiltersMobile } from "./components/Filters/MobileFilters";
import { Share } from "./components/Share/Share";
import { PrintPdf } from "./components/PrintPdf/PrintPdf";

function App() {
  return (
    <div>
      <div className="pdfPrint">
        <PrintPdf />
      </div>
      <div className="App">
        <div className="aside">
          <Filters />
          <FiltersMobile />
        </div>
        <Cards />
      </div>
      <Share />
    </div>
  );
}

export default App;
