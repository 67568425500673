import React, { useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import css from "./SightInput.module.css";
import { sightCollection } from "./SightItput.constant";

export const SightInput = () => {
  const { filters, setFilters } = useAppContext();

  const [seqrchQuery, setSeqrchQuery] = useState("");

  const itemsToView = sightCollection.sort().filter((el) => {
    if (seqrchQuery === "") return true;
    return el.toLowerCase().indexOf(seqrchQuery.toLowerCase()) > -1;
  });
  return (
    <div className={css.container}>
      <p>
        <b>Места</b>
      </p>
      <input
        value={seqrchQuery}
        onChange={(ev) => setSeqrchQuery(ev.target.value)}
        placeholder="Поиск мест"
        className={css.search}
      />
      <div className={css.itemsWrapper}>
        {itemsToView.length ? (
          itemsToView.map((el) => {
            const isChecked = (filters?.sights || []).indexOf(el) > -1;
            const onChange = () => {
              if (isChecked) {
                if (Array.isArray(filters?.sights)) {
                  setFilters({
                    ...filters,
                    sights: (filters?.sights || []).filter(
                      (item) => item !== el
                    ),
                    paginationPage: 0,
                  });
                } else {
                  setFilters({
                    ...filters,
                    sights: [],
                    paginationPage: 0,
                  });
                }
              } else {
                setFilters({
                  ...filters,
                  sights: [...(filters?.sights || []), el],
                  paginationPage: 0,
                });
              }
            };
            return (
              <label key={el}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={onChange}
                />
                <span className={css.label}>{el}</span>
              </label>
            );
          })
        ) : (
          <p>Данных нет измините параметры поиска</p>
        )}
      </div>
    </div>
  );
};
