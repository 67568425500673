import React from "react";
import css from "./Document.module.css";
import { useAppContext } from "../../../context/AppContext";
import { getFilteredTours } from "../../Cards/Cards.utils";
import { getDateFromTimeStampInDay } from "../../../utils/date.utils";

export const Document = () => {
  const { tours, filters } = useAppContext();
  const { data } = getFilteredTours(tours, filters);

  return (
    <div className={css.wrapper}>
      <div>
        <div className={css.headerWrapper}>
          <div>
            <img
              src="https://welcome-belarus.ru/wp-content/uploads/2018/08/Welcome-Belarus-logo.jpg"
              alt="welcome belarus logo"
            />
          </div>
          <p>
            Туроператор <b>"Ваш отдых"</b> Организуем туры по Беларуси с 2004
            года
          </p>
        </div>
        <h2>На все вопросы знает ответ ваш консультант</h2>
        <div className={css.consultantRow}>
          <div>
            <img
              src="https://welcome-belarus.ru/wp-content/uploads/2018/08/yulia.jpg"
              style={{ borderRadius: "50%", width: "100%", height: "auto" }}
              alt="welcome belarus manager"
            />
          </div>
          <div>
            <div>
              <h3 className={css.cardTitle}>Юлия Хавронич</h3>
              <p>
                <b>+375 33 6-680-680</b>
              </p>
              <p>
                <b>+375 29 818-02-80</b>
              </p>
              <p>Звонки, Viber, Telegram, WhatsApp</p>
              <p> E-mail: incoming@vash-otdyh.by</p>
            </div>
          </div>
        </div>
        <p className={css.heading}>
          Ждем Вас в нашей гостеприимной Беларуси со всей щедростью и
          открытостью белорусской души.
        </p>
        <p className={css.podborTitle}>Результаты подбора тура</p>
        <div className={css.cardWrapper}>
          {data.map((el, index) => {
            const startSting = el.start
              ? `${getDateFromTimeStampInDay(
                  new Date(el.start).getTime()
                )} - ${getDateFromTimeStampInDay(
                  new Date(
                    new Date(el.start).getTime() + el.days * 8.64e7
                  ).getTime()
                )}`
              : "любые даты выезда";
            const priceString = el.maxPrice
              ? `${el.minPrice} - ${el.maxPrice} RUR`
              : `от ${el.minPrice} RUR`;
            return (
              <div key={index}>
                <h3 className={css.cardTitle}>
                  {el.title.replaceAll("&#34;", '"')}
                </h3>
                <p>{el.route.replaceAll("&#34;", '"')}</p>
                <p>
                  {el?.startTime} {startSting}
                </p>
                <p>{priceString}</p>
              </div>
            );
          })}
        </div>
        <div>
          <h3>Главный офис</h3>
          Брест, ул. Машерова, 76
          <h3>Режим работы</h3>
          <p>
            <strong>Понедельник - пятница</strong>: с 9 - 19-00
            <br />
            <strong>Суббота, воскресенье</strong> - выходной
          </p>
          <h3>Контактные телефоны</h3>
          <p>
            <strong>Тел</strong>: +7 495 108-64-63
            <br />
            <strong>VEL</strong>: +375 29 6-990-680
            <br />
            <strong>MTC</strong>: +375 33 6-680-680
            <br />
            <strong>Факс</strong>: +375 162- 55-48-20
          </p>
          <p>
            E-mail:{" "}
            <a href="mailto:incoming@vash-otdyh.by">incoming@vash-otdyh.by</a>
          </p>
          <p>
            <strong>Отдел маркетинга</strong>: ads@vash-otdyh.by
          </p>
          <h3>Банковские реквизиты</h3>
          <ul>
            <li>р/сч. BY10ALFA30122482950040270000 в RUB в ЗАО «АЛЬФА-БАНК»</li>
            <li>БИК ALFABY2X</li>
            <li>УНП 101541947</li>
            <li>ОКПО 37526626</li>
            <li>220013 Минск, ул. Сурганова, 43-47</li>
          </ul>
          <h3>Платежи в российских рублях</h3>
          <ul>
            <li>
              Счет ЗАО «Альфа-Банк» №30111810100000000221 в АО «Альфа-Банк»,
              Москва, РФ
            </li>
            <li>
              БИК — 044525593, К/счет №30101810200000000593, ИНН 7728168971
            </li>
            <li>СВИФТ — ALFARUMM</li>
          </ul>
          <ul>
            <li>
              ОАО «БПС-СБЕРБанк» р/сч. BY82BPSB30121211590169330000 в
              Региональной Дирекции №100 г. Брест, ул. Мицкевича, 10.
            </li>
            <li>БИК BPSBBY2X</li>
            <li>р/сч. в российских рублях — BY83BPSB30121211590456430000</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
