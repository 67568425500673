import * as React from "react";
import ReactToPrint from "react-to-print";
import { Spinner } from "../Spinner/Spinner";
import css from "./PrintPdf.module.css";
import { Document } from "./Document/Document";

export const PrintPdf = () => {
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      // @ts-ignore
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        // @ts-ignore
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      // @ts-ignore
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button className={css.cta}>&#128438;Печать подборки туров</button>;
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="AwesomeFileName"
          onBeforeGetContent={handleOnBeforeGetContent}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
      )}
      <div className={css.hide}>
        <div ref={componentRef}>
          <Document />
        </div>
      </div>
    </div>
  );
};
