import css from "./TourTypeCheckbox.module.css";
import {
  TourCheckboxSelectType,
  useAppContext,
} from "../../../context/AppContext";
import React from "react";

export const TourTypeCheckbox = () => {
  const { filters, setFilters } = useAppContext();
  const isSbornyiChecked =
    filters.tourType === TourCheckboxSelectType.Sbornyi ||
    filters.tourType === TourCheckboxSelectType.All;
  const isZakaznoiChecked =
    filters.tourType === TourCheckboxSelectType.Zakaznoi ||
    filters.tourType === TourCheckboxSelectType.All;

  const onSbornyiClick = () => {
    switch (true) {
      case filters.tourType === TourCheckboxSelectType.Sbornyi:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Off,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.Zakaznoi:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.All,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.All:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Zakaznoi,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.Off:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Sbornyi,
          paginationPage: 0,
        });
    }
  };
  const onZakaznoiClick = () => {
    switch (true) {
      case filters.tourType === TourCheckboxSelectType.Zakaznoi:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Off,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.Sbornyi:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.All,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.All:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Sbornyi,
          paginationPage: 0,
        });
      case filters.tourType === TourCheckboxSelectType.Off:
        return setFilters({
          ...filters,
          tourType: TourCheckboxSelectType.Zakaznoi,
          paginationPage: 0,
        });
    }
  };
  return (
    <>
      <p>
        <b>Тип тура</b>
      </p>
      <div className={css.flexWrapperRow}>
        <label>
          <input
            type="checkbox"
            checked={isSbornyiChecked}
            onChange={onSbornyiClick}
          />
          <span className={css.label}>Сборный / регулярный</span>
        </label>
        <label>
          <input
            type="checkbox"
            checked={isZakaznoiChecked}
            onChange={onZakaznoiClick}
          />
          <span className={css.label}>Заказной</span>
        </label>
      </div>
    </>
  );
};
