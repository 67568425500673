import React from "react";
import { Card } from "./Card/Card";
import { filtersTypeStub, useAppContext } from "../../context/AppContext";
import css from "./Cards.module.css";
import { getFilteredTours } from "./Cards.utils";
import ReactPaginate from "react-paginate";
import {Spinner} from "../Spinner/Spinner";

export const Cards = () => {
  const { tours, filters, setFilters, isLoading } = useAppContext();

    if(isLoading) return <div className={css.spinnerContainer}><div><Spinner /><p>Загружаю...</p></div></div>
  const { data, total } = getFilteredTours(tours, filters);

  const onPaginationChange = (arg: { selected: number }) => {
    if (filters.paginationPage !== arg.selected) {
      setFilters({ ...filters, paginationPage: arg.selected });
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.wrapper}>
        {data
          .slice(
            filters.paginationPage === 0 ? 0 : filters.paginationPage * 5,
            filters.paginationPage === 0 ? 5 : (filters.paginationPage + 1) * 5
          )
          .map((el, index) => (
            <Card item={el} key={index} />
          ))}
        {total === 0 && (
          <>
            <h2>Не нашли тур по вашим параметрам?</h2>
            <h3>
              Позвоните нам{" "}
              <a href="tel:+7495 1086463" className={css.link}>
                +7 495 108-64-63
              </a>
              ,{" "}
              <a href="tel:+375336680680" className={css.link}>
                +375 33 6-680-680
              </a>{" "}
              или напишите на почту{" "}
              <a href="mailto:incoming@vash-otdyh.by" className={css.link}>
                incoming@vash-otdyh.by
              </a>{" "}
              и мы подберем тур под ваши параметры.
            </h3>
            <p>
              Вы также можете{" "}
              <span
                className={css.clean}
                onClick={() => setFilters(filtersTypeStub)}
              >
                сбросить <b>параметры поиска</b> на значения по умолчанию
              </span>
            </p>
          </>
        )}
      </div>
      <div className={css.paginationWrapper}>
        <ReactPaginate
          className={css.pagination}
          activeLinkClassName={css.selected}
          disabledClassName={css.disabled}
          breakLabel="..."
          nextLabel=">"
          onPageChange={onPaginationChange}
          pageRangeDisplayed={5}
          pageCount={total / 5}
          previousLabel="<"
          renderOnZeroPageCount={null}
          initialPage={0}
          forcePage={filters.paginationPage}
          marginPagesDisplayed={0}
        />
      </div>
    </div>
  );
};
