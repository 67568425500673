export const sightCollection = [
  "5 Форт",
  "Музей жд",
  "Наносы",
  "Августовский канал",
  "Барановичи",
  "Беловежская пуща",
  "Белхудожкерамика",
  "Березенский заповедник",
  "Березовка",
  "Бобруйск",
  "Большая Своротва",
  "Борки",
  "Браслав",
  "Брест",
  "Брестская крепость",
  "Будслав",
  "Буйничи",
  "Быхов",
  "Ветка",
  "Браславские озера",
  "Видзы",
  "Витебск",
  "Волковыск",
  "Воложин",
  "Вселюб",
  "Вязынка",
  "Гервяты",
  "Глубокое",
  "Голубая криница",
  "Гомель",
  "Гродно",
  "Дудутки",
  "Дукора",
  "Дятлово",
  "Евфрасиньевский монастырь",
  "Жиличи",
  "Жировичи",
  "Жодино",
  "Завод БелАЗ",
  "Залесье",
  "Заосье",
  "Заславль",
  "Здравнево",
  "Диприз",
  "Зоосад",
  "Ивенец",
  "Илово",
  "Каменец",
  "Каменецкая Вежа",
  "Кобрин",
  "Коробчицы",
  "Коссово",
  "Красносельский",
  "Красный Берег",
  "Курган Славы",
  "Лавришево",
  "Лепель",
  "Лида",
  "Лидский замок",
  "Лидский пивзавод",
  "Лидское пиво",
  "Линия Сталина",
  "Любча",
  "Меловые карьеры",
  "Минск",
  "Мир",
  "Могилев",
  "Мотоль",
  "Музей ЖД",
  "Музей истории религии",
  "Музей И. Репина",
  "Музей М. Шагала",
  "Музей книгопечатанья",
  "Мурованка",
  "Национальная библиотека",
  "Несвиж",
  "Новогрудок",
  "Новоселье",
  "Свитязь",
  "Оливария",
  "Островец",
  "Пинск",
  "Подороск",
  "Полоцк",
  "Полоцкая София",
  "Радошковичи",
  "Раков",
  "Раубичи",
  "Россь",
  "Рубежевичи",
  "Ружаны",
  "Святск",
  "Славгород",
  "Слободка",
  "Слоним",
  "Смиловичи",
  "Сморгонь",
  "Солы",
  "Станьково",
  "Стеклозавод Неман",
  "Страусиная ферма",
  "Строчицы",
  "Сула",
  "Сынковичи",
  "Сыроварня Грандмилк",
  "Троицкое предместье",
  "Хатынь",
  "Экотропа Белокорец",
  "Coca-Cola",
];
