export const getSearchToursData = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = await fetch(
    "https://welcome-belarus.ru/wp-content/uploads/crm/requests/getSearchTourData.php",
    requestOptions
  ).then((response) => response.json());

  return data[0].doc;
};
