import React from "react";
import { filtersTypeStub, useAppContext } from "../../context/AppContext";
import css from "./Filters.module.css";
import { TourTypeCheckbox } from "./TourTypeCheckbox/TourTypeCheckbox";
import { SightInput } from "./SightInput/SightInput";
import "react-range-slider-input/dist/style.css";
import cx from "classnames";
// @ts-ignore
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "react-modern-drawer/dist/index.css";

export const Filters = () => {
  const { filters, setFilters } = useAppContext();

  return (
    <div className={cx(css.wrapper)}>
      <div className={css.desktopFilters}>
        <div className={css.container}>
          <div className={css.filterFlexContainer}>
            <p>
              <b>Все фильтры</b>
            </p>
            <button
              className={css.clean}
              onClick={() => setFilters(filtersTypeStub)}
            >
              Сбросить
            </button>
          </div>
          <div className={css.filterContainer}>
            <input
              className={css.fullWidth}
              type="text"
              value={filters.searchQuery}
              onChange={(val) =>
                setFilters({
                  ...filters,
                  searchQuery: val.target.value,
                  paginationPage: 0,
                })
              }
              placeholder="Поиск по названию и маршруту"
            />
          </div>
          <div className={css.filterContainer}>
            <p>
              <b>Цена</b>
            </p>
            <div className={css.flexWrapper}>
              <span className={css.rangeLabel}>{filters.priceStart}</span>
              <RangeSlider
                defaultValue={[filters.priceStart, filters.priceEnd]}
                value={[filters.priceStart, filters.priceEnd]}
                onInput={(value: number[]) =>
                  setFilters({
                    ...filters,
                    priceStart: value[0],
                    priceEnd: value[1],
                    paginationPage: 0,
                  })
                }
                min={0}
                max={100000}
              />
              <span className={css.rangeLabel}>{filters.priceEnd}</span>
            </div>
          </div>
          <div className={css.filterContainer}>
            <p>
              <b>Старт тура</b>
            </p>
            <div className={css.flexWrapper}>
              <input
                type="date"
                value={filters.dayStart}
                onChange={(val) =>
                  setFilters({
                    ...filters,
                    dayStart: val.target.value,
                    paginationPage: 0,
                  })
                }
                className={css.halfWidth}
                placeholder="Поиск"
              />
              <input
                type="date"
                value={filters.dayEnd}
                onChange={(val) =>
                  setFilters({
                    ...filters,
                    dayEnd: val.target.value,
                    paginationPage: 0,
                  })
                }
                className={css.halfWidth}
                placeholder="Поиск"
              />
            </div>
          </div>
          <div className={css.filterContainer}>
            <p>
              <b>Количество дней</b>
            </p>
            <select
              value={filters.daysDuration}
              onChange={(val) =>
                setFilters({
                  ...filters,
                  daysDuration: val.target.value as unknown as number,
                  paginationPage: 0,
                })
              }
              className={css.select}
            >
              {[1, 2, 3, 4, 5, 6, 7, "Все"].map((el) => (
                <option value={el} key={`${el}-desktop`}>
                  {el}
                </option>
              ))}
            </select>
          </div>
          <div className={css.filterContainer}>
            <TourTypeCheckbox />
          </div>
          <div className={css.filterContainer}>
            <SightInput />
          </div>
        </div>
      </div>
    </div>
  );
};
