import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";

import { TourItemsType } from "../types/App.types.";
import { today } from "../utils/date.utils";
import queryString from "query-string";
import { getSearchToursData } from "../api/api";

export enum TourCheckboxSelectType {
  Sbornyi = "Сборный",
  Zakaznoi = "Заказной",
  All = "Все",
  Off = "Off",
}
export type FiltersType = {
  searchQuery: string;
  priceStart?: number;
  priceEnd?: number;
  daysDuration?: number | string;
  tourType?: TourCheckboxSelectType;
  sights: string[];
  dayStart?: string;
  dayEnd?: string;
  paginationPage: number;
};

export const filtersTypeStub = {
  searchQuery: "",
  priceStart: 0,
  priceEnd: 100000,
  dayStart: today(),
  dayEnd: undefined,
  daysDuration: "Все",
  tourType: TourCheckboxSelectType.All,
  sights: [],
  paginationPage: 0,
};

type AppContextType = {
  filters: FiltersType;
  setFilters: (arg: FiltersType) => void;
  tours: TourItemsType[];
  setTours: (arg: TourItemsType[]) => void;
  isLoading: boolean;
};

export const AppContext = createContext<AppContextType>({
  filters: filtersTypeStub,
  setFilters: () => {},
  tours: [],
  setTours: () => {},
  isLoading: false,
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tours, setTours] = useState<TourItemsType[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const getFilters = (location: string) => {
    const obj = location.split("?=")[1];
    if (obj) {
      const parsed = queryString.parse(obj) as unknown as FiltersType;
      const sight = Array.isArray(parsed.sights)
        ? parsed.sights
        : [parsed.sights];
      return { ...filtersTypeStub, ...parsed, sight };
    } else {
      return { ...filtersTypeStub };
    }
  };
  const [filters, setFilters] = useState<FiltersType>(
    getFilters(window.location.href)
  );


  const initializeTours = async () => {
    setIsLoading(true)
    const data = await getSearchToursData();
    setTours(JSON.parse(data));
    setFilters(getFilters(window.location.href))
    setIsLoading(false)
  };
  useEffect(() => {
    initializeTours();
  }, []);

  const setFiltersCallback = useCallback(
    (arg: FiltersType) => {
      const query = queryString.stringify(arg);
      const currUrl = window.location.href;
      const baseUrl = currUrl.split("?=")[0];
      console.log(baseUrl, query)
      window.history.replaceState(null, window.location.href, `${baseUrl}?=${query}`);
      setFilters(arg);
    },
    [filters, window.location.href]
  );

  const value = useMemo(() => {
    return {
      filters,
      setFilters: setFiltersCallback,
      tours,
      setTours,
      isLoading,
    };
  }, [filters, tours, isLoading]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("AppContext must be written in AppContextProvider");
  }
  return context;
}
