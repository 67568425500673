import { TourItemsType, TourItemType } from "../../types/App.types.";
import { FiltersType, TourCheckboxSelectType } from "../../context/AppContext";

export const getFilteredTours = (
  tours: TourItemsType[],
  filters: FiltersType
): { data: TourItemsType[]; total: number } => {
  let result = [...tours].filter((el) => el.link);

  if (filters.searchQuery !== "") {
    result = result.filter((el) => {
      const isInTitle =
        el.title.toLowerCase().indexOf(filters.searchQuery?.toLowerCase()) > -1;
      const isInRoute =
        el.route.toLowerCase().indexOf(filters.searchQuery?.toLowerCase()) > -1;
      return isInTitle || isInRoute;
    });
  }

  if (filters.priceStart) {
    result = result.filter((el) => el.minPrice > (filters?.priceStart || 0));
  }

  if (filters.priceEnd) {
    result = result.filter(
      (el) => (el?.maxPrice || el.minPrice) < (filters?.priceEnd || 100000)
    );
  }

  if (filters.daysDuration !== "Все") {
    result = result.filter((el) => el.days === Number(filters.daysDuration));
  }

  if (
    filters.tourType !== TourCheckboxSelectType.All &&
    filters.tourType !== TourCheckboxSelectType.Off
  ) {
    result = result.filter((el) => {
      const isSbornyi =
        el.type === TourItemType.Sbornyi ||
        el.type === TourItemType.SbornyiExcursion;
      if (filters.tourType === TourCheckboxSelectType.Sbornyi) {
        return isSbornyi;
      } else {
        return !isSbornyi;
      }
    });
  }

  if (filters.sights.length) {
    result = result.filter((el) => {
      let flag = true;
      for (let i of filters.sights) {
        if (el.route.toLowerCase().indexOf(i.toLowerCase()) === -1) {
          flag = false;
        }
      }
      return flag;
    });
  }

  if (filters.dayStart) {
    result = result.filter((el) => {
      const selectedTimestamp =
        new Date(filters?.dayStart || "").getTime() - 8.64e7;
      if (el.start) {
        return (
          new Date(el.start.replace(/\./g, "/")).getTime() > selectedTimestamp
        );
      } else {
        return true;
      }
    });
  }

  if (filters.dayEnd) {
    result = result.filter((el) => {
      const selectedTimestamp = new Date(filters?.dayEnd || "").getTime();
      if (el.start) {
        return (
          new Date(el.start.replace(/\./g, "/")).getTime() < selectedTimestamp
        );
      } else {
        return true;
      }
    });
  }

  return {
    data: result.sort(
      (a, b) =>
        new Date(a?.start?.replace(/\./g, "/") || Infinity).getTime() -
        new Date(b?.start?.replace(/\./g, "/") || Infinity).getTime()
    ),
    total: result.length,
  };
};
