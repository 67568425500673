import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  MailruShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  MailruIcon,
  OKIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import css from "./Share.module.css";
import queryString from "query-string";
import { FiltersType, useAppContext } from "../../context/AppContext";

export const Share = () => {
  const title = "Туры в Беларусь, результаты подбора тура";
  const { filters } = useAppContext();

  const getSharedUrl = (arg: FiltersType) => {
    const query = queryString.stringify(arg);
    const currUrl = window.location.href;
    const baseUrl = currUrl.split("?=")[0];
    return `${baseUrl}?=${query}`;
  };
  const shareUrl = getSharedUrl(filters);
  const exampleImage =
    "https://welcome-belarus.ru/wp-content/uploads/2018/08/Welcome-Belarus-logo.jpg";
  return (
    <div className={css.wrapper}>
      <h3>Поделитесь подборкой туров с друзьями</h3>

      <div className={css.container}>
        <div className="Demo__container">
          <div className="Demo__some-network">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={48} round />
            </FacebookShareButton>
          </div>

          <div className="Demo__some-network">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={48} round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network">
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={48} round />
            </TelegramShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network">
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={48} round />
            </WhatsappShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network">
            <LinkedinShareButton
              url={shareUrl}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
          </div>

          <div className="Demo__some-network">
            <VKShareButton
              url={shareUrl}
              image={`${String(window.location)}/${exampleImage}`}
              className="Demo__some-network__share-button"
            >
              <VKIcon size={48} round />
            </VKShareButton>
          </div>

          <div className="Demo__some-network">
            <OKShareButton
              url={shareUrl}
              image={`${String(window.location)}/${exampleImage}`}
              className="Demo__some-network__share-button"
            >
              <OKIcon size={48} round />
            </OKShareButton>
          </div>

          <div className="Demo__some-network">
            <MailruShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <MailruIcon size={48} round />
            </MailruShareButton>
          </div>

          <div className="Demo__some-network">
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={48} round />
            </EmailShareButton>
          </div>
          <div className="Demo__some-network">
            <ViberShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button"
            >
              <ViberIcon size={48} round />
            </ViberShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};
